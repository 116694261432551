import LayoutAuth from '@/features/auth/LayoutAuth';
import LayoutDefault from '@/features/layoutStandart/LayoutDefault';
import LayoutPrint from '@/features/print/LayoutPrint';
import {shallowRef, watch} from "vue";

const layout = shallowRef(LayoutDefault);

const refresh = (to) => {
  try {
    layout.value = { LayoutAuth, LayoutDefault, LayoutPrint, }[to.meta.layout] || LayoutDefault;
  } catch (e) {
    layout.value = LayoutDefault;
  }
}


export const useLayoutWatcher = (route) => {
  watch(route, (to) => refresh(to))
  return {
    layout,
  }
}
