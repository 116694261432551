import {ref, watch} from "vue";
import {useEntity} from "@/features/api/useEntity.js";
import {useAuth} from "@/features/auth/useAuth.js";

const logoUrl = ref(null)
const themeKey = ref(null)

const auth = useAuth()
watch(auth.user, () => loadData())

const entityRetailBranding = useEntity('RetailBranding')

const loadData = async () => {
  if (!auth.user.value) return
  const res = await entityRetailBranding.get(null)
  logoUrl.value = res.result.logoUrl
  if (!res?.result?.color?.startsWith('#')) themeKey.value = res.result.color
}

export const useRetailBranding = () => {
  return {
    logoUrl,
    themeKey,
    loading: entityRetailBranding.loading,

    update: async (logoId, themeKey) => {
      await entityRetailBranding.update(null, {
        logoId,
        color: themeKey,
    })
      await loadData()
    },
  }
}
