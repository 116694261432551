import {watch} from "vue";

export const useAuthWall = (router, auth) => {

  const validateRoute = (args) => {
    const [user, route, loading] = args;
    if (loading) return
    const routeAuthNotRequired = route?.meta?.authNotRequired ?? false

    const userHaveBeenLoggedOut = !user && !routeAuthNotRequired
    const userHaveBeenLoggedIn = !!user && routeAuthNotRequired

    if (userHaveBeenLoggedOut || userHaveBeenLoggedIn) {
      router.push('/')
    }
  }
  watch([auth.user, router.currentRoute, auth.loading], (args) => validateRoute(args), {immediate: true})

}

