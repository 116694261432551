import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

const _withScopeId = n => (_pushScopeId("data-v-942705f2"), n = n(), _popScopeId(), n);

const _hoisted_1 = {
  class: "itemRelateds"
};
const _hoisted_2 = {
  class: "header"
};
const _hoisted_3 = {
  class: "items"
};
import ItemCard from "@/features/catalog/itemCard/ItemCard.vue";
export default {
  __name: 'ItemRelateds',
  props: {
    title: String,
    items: Array
  },

  setup(__props) {
    const props = __props;
    return (_ctx, _cache) => {
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, _toDisplayString(props.title), 1), _createElementVNode("div", _hoisted_3, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(props.items, i => {
        return _openBlock(), _createBlock(ItemCard, {
          key: i,
          class: "item",
          item: i
        }, null, 8, ["item"]);
      }), 128))])]);
    };
  }

};