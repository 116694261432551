import { ref, reactive } from 'vue';
import { compact, join, get } from 'lodash-es';
import {useBackend} from "@/features/api/useBackend.js";

const extractDataFromResponse = (responseBody) => {
  const result = responseBody?.result
  const count = responseBody?.result?.count
  const filters = responseBody?.result?.filter
  const sorts = responseBody?.result?.sorts
  for (const p in result) {
    if (p === 'count') continue
    if (p === 'filter') continue
    if (p === 'sorts') continue
    return {
      result: result[p],
      count,
      filters,
      sorts,
    }
  }
  return {result, count, filters}
}

const cache = reactive({});

export const useEntity = (endpoint, options = {
  showNotificationOnError: true,
}) => {
  const backend = useBackend(options)

  const loading = ref(false);
  endpoint = 'entity' + endpoint
  const entries = {
    list: join(compact([endpoint, 'list']), '/'),
    item: join(compact([endpoint, 'get']), '/'),
    delete: join(compact([endpoint, 'delete']), '/'),
    update: join(compact([endpoint, 'update']), '/'),
    create: join(compact([endpoint, 'create']), '/'),
  };

  const keep = (responseData) => {
    if (!responseData) return responseData
    if (endpoint !== 'entityCatalogCategory') return responseData
    cache[endpoint] = responseData
    return responseData
  }

  return {
    loading,

    list: async (data) => {
      loading.value = true

      if (get(cache, endpoint)) {
        return new Promise((resolve) => resolve(get(cache, endpoint)))
            .finally(() => loading.value = false)
      }

      try {
        const res = await backend.api(entries.list, data)
        return keep(extractDataFromResponse(res?.data))
      } finally {
        loading.value = false
      }
    },

    get: async (id) => {
      try {
        loading.value = true
        const res = await backend.api(entries.item, {id})
        return extractDataFromResponse(res?.data)

      } finally {
        loading.value = false
      }
    },

    delete: async (id) => {
      try {
        loading.value = true
        const res = await backend.api(entries.delete, {id})
        return res?.data

      } finally {
        loading.value = false
      }
    },

    update: async (id, payload) => {
      try {
        loading.value = true;
        const res = await backend.api(entries.update, { id, payload })
        return res?.data

      } finally {
        loading.value = false
      }
    },

    create: async (payload) => {
      try {
        loading.value = true;
        const res = await backend.api(entries.create, { payload })
        return res?.data

      } finally {
        loading.value = false
      }
    },
  }
};
