import {computed} from "vue";
import {useAuth} from "@/features/auth/useAuth.js";


const auth = useAuth()

const permissions = computed(() => {
  return auth?.user?.value?.permissions ?? []
})

export const usePermissions = () => {
  return {
    permissions,

    has: (permissionName) => {
      if (!permissions.value) return false
      return permissions.value.includes(permissionName)
    }
  }
}
