import { unref as _unref, normalizeClass as _normalizeClass, createVNode as _createVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

const _withScopeId = n => (_pushScopeId("data-v-e3533ba0"), n = n(), _popScopeId(), n);

const _hoisted_1 = {
  key: 0,
  class: "counter"
};
import { computed } from "vue";
import { Icon } from "@iconify/vue";
import { serviceNoticeReceiver } from "@/features/notices/serviceNoticeReceiver.js";
export default {
  __name: 'NoticeIndicator',

  setup(__props) {
    const messageReceiver = serviceNoticeReceiver();
    const unreadCount = computed(() => {
      return messageReceiver?.unread.value.length;
    });
    const blinking = computed(() => {
      return unreadCount.value > 0;
    });
    return (_ctx, _cache) => {
      const _component_router_link = _resolveComponent("router-link");

      return _openBlock(), _createBlock(_component_router_link, {
        to: {
          name: 'notices.representation'
        },
        class: "noticeIndicator",
        title: "Уведомления"
      }, {
        default: _withCtx(() => [_createVNode(_unref(Icon), {
          class: _normalizeClass(["icon", {
            blinkMe: _unref(blinking)
          }]),
          icon: "bx:bell"
        }, null, 8, ["class"]), _unref(blinking) ? (_openBlock(), _createElementBlock("div", _hoisted_1, _toDisplayString(_unref(unreadCount)), 1)) : _createCommentVNode("", true)]),
        _: 1
      }, 8, ["to"]);
    };
  }

};