import {computed, ref, watch} from "vue";
import {useEntity} from "@/features/api/useEntity.js";
import {useAuth} from "@/features/auth/useAuth.js";
import {useNotification} from "@/features/notifications/useNotification.js";
import {getNumEnding} from "@/features/utils/utils.js";
import {CONFIG} from "@/config.js";


const entityNotification = useEntity('Notification')

const messages = ref([])

const lastMessageId = computed(() => {
  return messages.value[0]?.id
})

const unread = computed(() => {
  return messages.value.filter((m) => m?.wasRead === false)
})

const showNotifications = ref(true)
const showNotification = () => {
  useNotification().show({
    type: 'info',
    title: 'У вас '
        + unread.value.length
        + getNumEnding(unread.value.length, [
            ' непрочитанное уведомление',
            ' непрочитанных уведомления',
            ' непрочитанных уведомлений',
        ]),
  })
}

const update = async () => {
  if (messages.value.length === 0) {
    const res = await entityNotification.list()
    messages.value = res.result

    if (unread.value.length > 0 && showNotifications.value) {
      showNotification()
    }

  } else {
    const res = await entityNotification.list({
      filter: {
        "id": lastMessageId.value,
      },
    })
    messages.value = [
        ...res.result,
        ...messages.value,
    ]

    if (res.result.length > 0 && showNotifications.value) {
      showNotification()
    }
  }
}


const isActive = ref(false)

const UPDATE_RATE = (
    process.env?.VUE_APP_B2B_SERVICE_MESSAGE_RECEIVER_UPDATE_RATE_SECONDS
    ?? CONFIG.notifications.updateEveryNSeconds
    ?? 35
) * 1000;


const looper = async () => {
  if (isActive.value) {
    await update()
    setTimeout(looper, UPDATE_RATE)
  }
}

watch(isActive, (isActive) => {
  if (isActive) looper()
}, {immediate: true})


const auth = useAuth()
watch([auth.user], ([user]) => {
  isActive.value = !!user
  if (!user) {
    messages.value = []
  }
}, {immediate: true})




export const serviceNoticeReceiver = () => {
  return {
    messages,
    isActive,
    update,
    lastMessageId,

    unread,
    showNotifications,

    readAll: async () => {
      await entityNotification.update(lastMessageId.value, {})
      messages.value = []
      await update()
    }
  }
}
