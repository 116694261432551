import { unref as _unref, resolveDirective as _resolveDirective, createElementVNode as _createElementVNode, withDirectives as _withDirectives, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue";
const _hoisted_1 = ["innerHTML"];
import { computed, ref, watch, onMounted } from "vue";
import { useRoute } from "vue-router";
import { useUserPages } from "@/features/userPages/useUserPages.js";
import { useEntity } from "@/features/api/useEntity.js";
import PageContainer from "@/features/layoutStandart/PageContainer.vue";
import { useNavWatcher } from "@/features/navWatcher/useNavWatcher.js";
export default {
  __name: 'PageUserPage',

  setup(__props) {
    const route = useRoute();
    const menu = useUserPages();
    const entityMenuItem = useEntity('MenuItem');
    onMounted(() => {
      loadContent();
    });
    const itemFromUrl = computed(() => route.params?.name ?? null);
    watch([itemFromUrl, menu.loading], () => loadContent());
    const content = ref('');

    const loadContent = async () => {
      if (menu.loading.value) return;
      const item = menu.items?.value.find(item => item.label === itemFromUrl.value);
      if (!item) return;
      const res = await entityMenuItem.get(item.placement + '+' + item.label);
      content.value = res.result.content;
      window.scrollTo(0, 0);
      useNavWatcher().title.value = item?.label ?? '';
    };

    return (_ctx, _cache) => {
      const _directive_loading = _resolveDirective("loading");

      return _openBlock(), _createBlock(PageContainer, null, {
        default: _withCtx(() => [_withDirectives(_createElementVNode("div", {
          style: {
            "min-height": "50px"
          },
          innerHTML: content.value
        }, null, 8, _hoisted_1), [[_directive_loading, _unref(menu).loading.value]])]),
        _: 1
      });
    };
  }

};