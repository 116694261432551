import { unref as _unref, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, Fragment as _Fragment, createBlock as _createBlock, resolveDirective as _resolveDirective, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

const _withScopeId = n => (_pushScopeId("data-v-122c3995"), n = n(), _popScopeId(), n);

const _hoisted_1 = ["src"];
const _hoisted_2 = {
  key: 0,
  class: "offerTitle"
};
const _hoisted_3 = {
  style: {
    "text-align": "right"
  }
};
const _hoisted_4 = {
  class: "quantity"
};
const _hoisted_5 = {
  key: 0
};
const _hoisted_6 = {
  class: "remove"
};
const _hoisted_7 = {
  key: 0,
  style: {
    "text-align": "right",
    "font-weight": "bold"
  }
};
const _hoisted_8 = {
  key: 1,
  style: {
    "text-align": "right"
  }
};
import { watch, ref, unref, onMounted, computed } from "vue";
import { useBasket } from "@/features/baskets/useBasket.js";
import { useEntity } from "@/features/api/useEntity.js";
import { normalizePhotoUrl, moneyFormat } from "@/features/utils/utils.js";
import { Icon } from "@iconify/vue";
import ItemAvailability from "@/features/ui/ItemAvailability.vue";
import { usePriceBin } from "@/features/prices/usePriceBin.js";
import { useBundleAmounts } from "@/features/baskets/useBundleAmounts.js";
export default {
  __name: 'BasketItemsView',
  props: ['basket', 'disableChangeAmount', 'basketId', 'orderId'],

  setup(__props) {
    const props = __props;
    const priceBin = usePriceBin();
    const basketModule = useBasket();
    const bundleEntity = useEntity('Bundle');
    const bundles = ref([]);
    onMounted(() => {
      updateBundles();
    });
    watch(props, async p => await updateBundles(p?.basket));

    const updateBundles = async () => {
      const filter = {};
      if (props?.basketId) filter.basketId = unref(props?.basketId);else if (props?.orderId) filter.orderId = unref(props?.orderId);
      const res = await bundleEntity.list({
        filter
      });
      bundles.value = res.result;
    };

    const possibleToChangeAmounts = computed(() => {
      return !props.disableChangeAmount && props.basketId === basketModule.curBasketId.value;
    });

    const removeBundle = async bundle => {
      await basketModule.removeBundle(bundle.id);
    };

    const loading = computed(() => basketModule.loading.value || bundleEntity.loading.value);

    const selectedItemOffer = bundle => {
      return bundle.item.offers.find(io => {
        return "" + io.id === bundle.itemOfferId;
      }) ?? null;
    };

    const tableData = computed(() => {
      if (bundles.value.length === 0) return [];
      return bundles.value.map(b => {
        const itemOffer = selectedItemOffer(b);
        const price = priceBin.get(itemOffer?.price);
        return {
          id: b?.id,
          itemId: b?.item?.id,
          a: b?.item?.a,
          title: b?.item?.title,
          offerTitle: itemOffer?.title ?? '(неизвестно)',
          price: moneyFormat(price),
          priceRaw: price,
          amount: b?.amount,
          availability: itemOffer?.balance > 0,
          photo: normalizePhotoUrl(b?.item?.photoMain),
          total: moneyFormat(price, {
            valueMultiplier: b?.amount
          })
        };
      });
    }); //
    // Изменение количества товаров
    //

    const bam = useBundleAmounts();
    const bufferAmounts = bam.bufferAmounts;

    const showUpdateButton = bundle => {
      return bam.bufferHasChanges(bundle.id);
    };

    const canChangeAmount = bundle => {
      for (const b of bundles.value) {
        if (b.id === bundle.id) continue;
        if (showUpdateButton(b)) return false;
      }

      return true;
    };

    const link = scopeRow => {
      return {
        name: 'item.detailed',
        params: {
          id: scopeRow.itemId
        }
      };
    };

    const confirmBuffer = async scopeRow => {
      await bam.confirmBuffer(scopeRow.id);
    };

    const resetBuffer = scopeRow => {
      bam.resetBuffer(scopeRow.id);
    };

    return (_ctx, _cache) => {
      const _component_el_table_column = _resolveComponent("el-table-column");

      const _component_router_link = _resolveComponent("router-link");

      const _component_el_input_number = _resolveComponent("el-input-number");

      const _component_el_popconfirm = _resolveComponent("el-popconfirm");

      const _component_el_button = _resolveComponent("el-button");

      const _component_el_container = _resolveComponent("el-container");

      const _component_el_table = _resolveComponent("el-table");

      const _directive_loading = _resolveDirective("loading");

      return _withDirectives((_openBlock(), _createBlock(_component_el_container, {
        class: "basketItemView"
      }, {
        default: _withCtx(() => [_createVNode(_component_el_table, {
          data: _unref(tableData),
          "table-layout": "auto"
        }, {
          default: _withCtx(() => [_createVNode(_component_el_table_column, {
            prop: "a",
            label: "Артикул",
            width: "110"
          }), _createVNode(_component_el_table_column, {
            prop: "photo",
            width: "60"
          }, {
            default: _withCtx(scope => [scope.row.photo ? (_openBlock(), _createElementBlock("img", {
              key: 0,
              src: scope.row.photo,
              alt: "",
              height: "40",
              width: "40",
              class: "imgPhoto"
            }, null, 8, _hoisted_1)) : _createCommentVNode("", true)]),
            _: 1
          }), _createVNode(_component_el_table_column, {
            prop: "title",
            label: "Наименование"
          }, {
            default: _withCtx(scope => [_createVNode(_component_router_link, {
              to: link(scope.row)
            }, {
              default: _withCtx(() => [_createTextVNode(_toDisplayString(scope.row.title), 1)]),
              _: 2
            }, 1032, ["to"]), scope.row.offerTitle ? (_openBlock(), _createElementBlock("div", _hoisted_2, _toDisplayString(scope.row.offerTitle), 1)) : _createCommentVNode("", true)]),
            _: 1
          }), _createVNode(_component_el_table_column, {
            prop: "availability",
            label: "Наличие"
          }, {
            default: _withCtx(scope => [_createVNode(ItemAvailability, {
              availability: scope.row.availability,
              short: true
            }, null, 8, ["availability"])]),
            _: 1
          }), _createVNode(_component_el_table_column, {
            prop: "price",
            label: "Цена",
            width: "140"
          }, {
            default: _withCtx(scope => [_createElementVNode("div", _hoisted_3, _toDisplayString(scope.row.price), 1)]),
            _: 1
          }), _createVNode(_component_el_table_column, {
            prop: "amount",
            label: "Кол-во",
            width: "100"
          }, {
            default: _withCtx(scope => [_createElementVNode("div", _hoisted_4, [!_unref(possibleToChangeAmounts) ? (_openBlock(), _createElementBlock("div", _hoisted_5, [scope.row?.amount ? (_openBlock(), _createElementBlock(_Fragment, {
              key: 0
            }, [_createTextVNode(_toDisplayString(scope.row.amount), 1)], 64)) : _unref(bufferAmounts)[scope.row?.id] ? (_openBlock(), _createElementBlock(_Fragment, {
              key: 1
            }, [_createTextVNode(_toDisplayString(_unref(bufferAmounts)[scope.row.id]), 1)], 64)) : _createCommentVNode("", true)])) : _createCommentVNode("", true), _unref(possibleToChangeAmounts) ? (_openBlock(), _createBlock(_component_el_input_number, {
              key: 1,
              modelValue: _unref(bufferAmounts)[scope.row.id],
              "onUpdate:modelValue": $event => _unref(bufferAmounts)[scope.row.id] = $event,
              min: 0,
              max: 1000,
              size: "small",
              disabled: !canChangeAmount(scope.row)
            }, null, 8, ["modelValue", "onUpdate:modelValue", "disabled"])) : _createCommentVNode("", true), _unref(possibleToChangeAmounts) ? (_openBlock(), _createBlock(_component_el_popconfirm, {
              key: 2,
              title: "Подтвердите удаление позиции",
              onConfirm: $event => removeBundle(scope.row),
              "confirm-button-text": "Удалить",
              "cancel-button-text": "Отмена"
            }, {
              reference: _withCtx(() => [_createElementVNode("div", _hoisted_6, [_createVNode(_unref(Icon), {
                icon: "bx:trash-alt",
                class: "icon"
              })])]),
              _: 2
            }, 1032, ["onConfirm"])) : _createCommentVNode("", true)])]),
            _: 1
          }), _createVNode(_component_el_table_column, {
            label: "Сумма",
            width: "140"
          }, {
            default: _withCtx(scope => [showUpdateButton(scope.row) ? (_openBlock(), _createElementBlock("div", _hoisted_7, _toDisplayString(_unref(moneyFormat)(scope.row.priceRaw, {
              valueMultiplier: _unref(bufferAmounts)[scope.row.id]
            })), 1)) : (_openBlock(), _createElementBlock("div", _hoisted_8, _toDisplayString(scope.row.total), 1))]),
            _: 1
          }), _createVNode(_component_el_table_column, {
            prop: "actions"
          }, {
            default: _withCtx(scope => [!__props.disableChangeAmount ? (_openBlock(), _createBlock(_component_el_container, {
              key: 0,
              class: "actions"
            }, {
              default: _withCtx(() => [showUpdateButton(scope.row) ? (_openBlock(), _createBlock(_component_el_button, {
                key: 0,
                type: "primary",
                size: "small",
                onClick: $event => confirmBuffer(scope.row)
              }, {
                default: _withCtx(() => [_createVNode(_unref(Icon), {
                  icon: "ci:check-bold",
                  class: "icon"
                })]),
                _: 2
              }, 1032, ["onClick"])) : _createCommentVNode("", true), showUpdateButton(scope.row) ? (_openBlock(), _createBlock(_component_el_button, {
                key: 1,
                type: "danger",
                size: "small",
                plain: "",
                onClick: $event => resetBuffer(scope.row),
                style: {
                  "margin-left": "5px"
                }
              }, {
                default: _withCtx(() => [_createVNode(_unref(Icon), {
                  icon: "ci:undo",
                  class: "icon"
                })]),
                _: 2
              }, 1032, ["onClick"])) : _createCommentVNode("", true)]),
              _: 2
            }, 1024)) : _createCommentVNode("", true)]),
            _: 1
          })]),
          _: 1
        }, 8, ["data"])]),
        _: 1
      })), [[_directive_loading, _unref(loading).value]]);
    };
  }

};