import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, unref as _unref, resolveDirective as _resolveDirective, createBlock as _createBlock, withDirectives as _withDirectives, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

const _withScopeId = n => (_pushScopeId("data-v-753d726c"), n = n(), _popScopeId(), n);

const _hoisted_1 = {
  class: "documentsList"
};
import { onMounted, ref } from "vue";
import DocumentsItem from "@/features/documents/representation/DocumentsItem.vue";
import { useEntity } from "@/features/api/useEntity.js";
export default {
  __name: 'DocumentsList',

  setup(__props) {
    onMounted(() => {
      loadDocuments();
    });
    const entityDocument = useEntity('Document');
    const documents = ref([]);

    const loadDocuments = async () => {
      const res = await entityDocument.list();
      documents.value = res.result;
    };

    return (_ctx, _cache) => {
      const _component_el_empty = _resolveComponent("el-empty");

      const _directive_loading = _resolveDirective("loading");

      return _openBlock(), _createElementBlock(_Fragment, null, [_createElementVNode("div", _hoisted_1, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(documents.value, doc => {
        return _withDirectives((_openBlock(), _createBlock(DocumentsItem, {
          key: doc.id,
          document: doc,
          onDeleted: loadDocuments
        }, null, 8, ["document"])), [[_directive_loading, _unref(entityDocument).loading.value]]);
      }), 128))]), documents.value?.length === 0 && !_unref(entityDocument).loading.value ? (_openBlock(), _createBlock(_component_el_empty, {
        key: 0,
        description: "Список документов пуст"
      })) : _createCommentVNode("", true)], 64);
    };
  }

};