import { unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, vShow as _vShow, createVNode as _createVNode, withDirectives as _withDirectives, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, withModifiers as _withModifiers, createTextVNode as _createTextVNode, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

const _withScopeId = n => (_pushScopeId("data-v-7413cc32"), n = n(), _popScopeId(), n);

const _hoisted_1 = {
  class: "offers"
};
const _hoisted_2 = ["onClick"];
const _hoisted_3 = {
  class: "imgWrapper"
};
const _hoisted_4 = {
  class: "body"
};
const _hoisted_5 = {
  class: "title"
};
const _hoisted_6 = {
  class: "subtitle"
};
const _hoisted_7 = {
  class: "price"
};
const _hoisted_8 = ["onClick"];
import { ref, computed } from "vue";
import { moneyFormat } from "@/features/utils/utils.js";
import ItemAvailability from "@/features/ui/ItemAvailability.vue";
import { usePriceBin } from "@/features/prices/usePriceBin.js";
import { Icon } from "@iconify/vue";
import { useEntity } from "@/features/api/useEntity.js";
import { statePageItem } from "@/features/item/statePageItem.js";
export default {
  __name: 'ItemActionsOfferSelectorList',
  props: ['limit'],

  setup(__props) {
    const props = __props;
    const state = statePageItem();

    const onOfferClick = offerId => {
      state.selectedItemOfferId.value = offerId;
    };

    const entityFavorite = useEntity('Favorite');

    const onFavoriteClick = async offer => {
      if (offer.isFavorite) {
        await entityFavorite.delete(offer.id);
      } else {
        await entityFavorite.create({
          id: offer.id
        });
      }

      await state.itemB.value?.load();
    };

    const isExpanded = ref(false);
    const offers = computed(() => {
      if (isExpanded.value) return state.item.value?.offers;
      return state.item.value?.offers?.slice(0, props.limit) ?? [];
    });
    const priceBin = usePriceBin();

    const preparePrice = priceSet => moneyFormat(priceBin.get(priceSet));

    return (_ctx, _cache) => {
      const _component_el_image = _resolveComponent("el-image");

      const _component_el_button = _resolveComponent("el-button");

      return _openBlock(), _createElementBlock("div", _hoisted_1, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(offers) ?? [], offer => {
        return _openBlock(), _createElementBlock("div", {
          class: _normalizeClass(["offer", {
            selected: _unref(state).selectedItemOfferId.value === offer.id
          }]),
          key: offer.id,
          onClick: $event => onOfferClick(offer.id)
        }, [_createElementVNode("div", _hoisted_3, [_withDirectives(_createVNode(_component_el_image, {
          class: "img",
          fit: "cover",
          src: offer.photo
        }, null, 8, ["src"]), [[_vShow, offer.photo]])]), _createElementVNode("div", _hoisted_4, [_createElementVNode("div", _hoisted_5, _toDisplayString(offer.title), 1), _createElementVNode("div", _hoisted_6, [_createVNode(ItemAvailability, {
          availability: offer.balance > 0
        }, null, 8, ["availability"])])]), _createElementVNode("div", _hoisted_7, _toDisplayString(preparePrice(offer.price) ?? 'По заявке'), 1), _createElementVNode("div", {
          class: "favIcon",
          onClick: _withModifiers($event => onFavoriteClick(offer), ["stop"])
        }, [_createVNode(_unref(Icon), {
          class: _normalizeClass(["icon", {
            favorite: offer.isFavorite
          }]),
          icon: offer.isFavorite ? 'ic:baseline-favorite' : 'ic:baseline-favorite-border'
        }, null, 8, ["class", "icon"])], 8, _hoisted_8)], 10, _hoisted_2);
      }), 128)), !isExpanded.value && props.limit < _unref(state).item.value?.offers?.length ? (_openBlock(), _createBlock(_component_el_button, {
        key: 0,
        type: "primary",
        plain: "",
        class: "expand",
        onClick: _cache[0] || (_cache[0] = $event => isExpanded.value = true)
      }, {
        default: _withCtx(() => [_createTextVNode(" Показать ещё " + _toDisplayString(_unref(state).item.value?.offers?.length - props.limit) + " предложений ", 1)]),
        _: 1
      })) : _createCommentVNode("", true)]);
    };
  }

};