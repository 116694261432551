export const appLog = (...args) => {
  console.log('PL_B2B', ...args)
}

export const normalizePhotoUrl = (url) => {
  return url
}

export const sleep = (ms) => {
  return new Promise(resolve => setTimeout(resolve, ms))
}

export const getReadableSize = (bytes, dp) => {
  const si = true
  const thresh = si ? 1000 : 1024

  if (Math.abs(bytes) < thresh) {
    return bytes + ' B'
  }

  const units = si
      ? ['KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
      : ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB']
  let u = -1
  const r = 10 ** dp

  do {
    bytes /= thresh
    ++u
  } while (Math.round(Math.abs(bytes) * r) / r >= thresh && u < units.length - 1)

  return bytes.toFixed(dp) + ' ' + units[u]
}


export const currencyToLocale = (currency) => {
  const c = currency.toLowerCase()
  if (c === 'rub') return 'ru-RU'
  if (c === 'usd') return 'en-US'
  if (c === 'eur') return 'de-DE'
  if (c === 'byn') return 'by-BY'
  return 'ru-RU'
}

export const moneyFormat = (money, options = {
  valueMultiplier: 1,
}) => {
  if ((money ?? null) === null) return null
  if ((money?.value ?? null) === null) return null
  if ((money?.currency ?? null) === null) return null

  const formatter = new Intl.NumberFormat(currencyToLocale(money.currency), {
    style: 'currency',
    currency: money.currency,
  })
  return formatter.format(money.value * options.valueMultiplier)
}

/**
 * Функция возвращает окончание для множественного числа слова на основании числа и массива окончаний
 * @param  iNumber Integer Число на основе которого нужно сформировать окончание
 * @param  aEndings Array Массив слов или окончаний для чисел (1, 4, 5),
 *         например ['яблоко', 'яблока', 'яблок']
 * @return String
 */

export const getNumEnding = (iNumber, aEndings) => {
  let sEnding, i;
  iNumber = iNumber % 100;
  if (iNumber >= 11 && iNumber <= 19) {
    sEnding = aEndings[2];
  } else {
    i = iNumber % 10;
    switch (i) {
      case (1):
        sEnding = aEndings[0];
        break;
      case (2):
      case (3):
      case (4):
        sEnding = aEndings[1];
        break;
      default:
        sEnding = aEndings[2];
    }
  }
  return sEnding;
}
