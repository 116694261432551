import {computed, reactive, ref, watch} from "vue";
import {useEntity} from "@/features/api/useEntity.js";
import {useAuth} from "@/features/auth/useAuth.js";
import {useBackend} from "@/features/api/useBackend.js";

// Используемые модули
const authModule = useAuth()
const backend = useBackend()
const basketEntity = useEntity('Basket')
const bundleEntity = useEntity('Bundle')

// Внутреннее экспортируемое состояние
const loading = computed(() => basketEntity.loading.value || bundleEntity.loading.value)
const baskets = reactive([]) // TODO: Мб тут нужен ref
const curBasketId = ref(null)

// Вычисляемые
const curBasket = computed(() => {
  return baskets?.value?.find((b) => b.id === curBasketId.value) ?? null
})
const bundlesOfCurrentBasket = ref([])

// watch'еры
watch(curBasketId, () => refreshBundles())
watch(authModule.user, async () => {
  await refresh()
})

const refresh = async () => {
  if (!authModule.user.value) {
    curBasketId.value = null
    curBasket.value = null
    return
  }

  baskets.value = (await basketEntity.list()).result
  if (curBasket.value === null) {
    if (baskets.value.length > 0) {
      curBasketId.value = baskets.value[0].id
    } else {
      curBasketId.value = null
    }
  }

  await refreshBundles()
}

const refreshBundles = async () => {
  if (curBasket.value === null) {
    bundlesOfCurrentBasket.value = []
    return
  }
  const res = (await
      bundleEntity.list({
        filter: {
          basketId: curBasketId.value
        }
      })
  ).result
  bundlesOfCurrentBasket.value = res
}

const setCurrentBasketId = async (basketId) => {
  if ((baskets?.value?.find((b) => b.id === basketId) ?? null) === null) return
  curBasketId.value = basketId
}

const createBasket = async () => {
  const id = (await basketEntity.create()).result.id
  await refresh()
  await setCurrentBasketId('' + id)
}

export const useBasket = () => {
  return {
    loading,
    baskets,
    curBasketId,
    curBasket,
    bundlesOfCurrentBasket,

    refresh,
    createBasket,
    setCurrentBasketId,

    deleteBasket: async (basketId) => {
      await basketEntity.delete(basketId)
      await refresh()
    },

    addItemToCurrentBasket: async (itemOfferId, amount) => {
      if (curBasketId.value === null) {
        await createBasket()
      }
      await bundleEntity.create({
        basketId: curBasketId.value,
        itemOfferId,
        amount,
      })
      await refresh()
    },

    updateBundle: async (bundleId, amount) => {
      if (curBasketId.value === null) return
      await bundleEntity.update(bundleId, {amount})
      await refresh()
    },

    removeBundle: async (bundleId) => {
      await bundleEntity.delete(bundleId)
      await refresh()
    },

    checkout: async (basketId, details) => {
      const res = await backend.api('basket/checkout', {
        basketId,
        shippingId: details.shippingId,
        legalId: details.legalId,
        paymentId: details.paymentId,
      })
      await refresh()
      return res.data.result
    },

    renameBasket: async (basketId, title) => {
      await basketEntity.update(basketId, {title})
      await refresh()
    }
  }
}
