import { createVNode as _createVNode, unref as _unref, resolveDirective as _resolveDirective, withDirectives as _withDirectives, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

const _withScopeId = n => (_pushScopeId("data-v-07f7aff2"), n = n(), _popScopeId(), n);

const _hoisted_1 = {
  class: "body"
};
import { ref, onMounted } from "vue";
import PageContainer from "@/features/layoutStandart/PageContainer.vue";
import PageHeader from "@/features/ui/PageHeader.vue";
import { useEntity } from "@/features/api/useEntity.js";
import OrdersTable from "@/features/orders/representation/OrdersTable.vue";
export default {
  __name: 'PageOrders',

  setup(__props) {
    const entityOrder = useEntity('Order');
    onMounted(() => {
      loadOrders();
    });
    const orders = ref([]);

    const loadOrders = async () => {
      const res = await entityOrder.list();
      orders.value = res.result;
    };

    const requireUpdate = () => {
      loadOrders();
    };

    return (_ctx, _cache) => {
      const _directive_loading = _resolveDirective("loading");

      return _openBlock(), _createBlock(PageContainer, {
        class: "pageOrders"
      }, {
        default: _withCtx(() => [_createVNode(PageHeader), _createElementVNode("div", _hoisted_1, [_withDirectives(_createVNode(OrdersTable, {
          orders: orders.value,
          onRequireUpdate: requireUpdate
        }, null, 8, ["orders"]), [[_directive_loading, _unref(entityOrder).loading.value]])])]),
        _: 1
      });
    };
  }

};