import { createVNode as _createVNode, unref as _unref, resolveDirective as _resolveDirective, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

const _withScopeId = n => (_pushScopeId("data-v-2dbf601a"), n = n(), _popScopeId(), n);

const _hoisted_1 = {
  key: 0,
  style: {
    "height": "30px"
  }
};
const _hoisted_2 = {
  key: 1,
  class: "news"
};
const _hoisted_3 = ["src"];
const _hoisted_4 = {
  class: "date"
};
const _hoisted_5 = ["innerHTML"];
import { ref, watch } from "vue";
import PageContainer from "@/features/layoutStandart/PageContainer.vue";
import PageHeader from "@/features/ui/PageHeader.vue";
import { useUrlBindingId } from "@/features/urlBinding/useUrlBindingId.js";
import { useNavWatcher } from "@/features/navWatcher/useNavWatcher.js";
export default {
  __name: 'PageNewsDetailed',

  setup(__props) {
    const binding = useUrlBindingId('News');
    watch([binding.entity], ([news]) => {
      if (news?.title) useNavWatcher().title.value = news.title;
    }, {
      immediate: true
    });
    const breadcrumb = ref([{
      text: 'Новости',
      to: '/news'
    }]);
    return (_ctx, _cache) => {
      const _directive_loading = _resolveDirective("loading");

      return _openBlock(), _createBlock(PageContainer, {
        class: "pageNewsDetailed"
      }, {
        default: _withCtx(() => [_createVNode(PageHeader, {
          breadcrumb: breadcrumb.value
        }, null, 8, ["breadcrumb"]), _unref(binding).loading.value ? _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_1, null, 512)), [[_directive_loading, _unref(binding).loading.value]]) : _createCommentVNode("", true), _unref(binding).entity.value ? (_openBlock(), _createElementBlock("div", _hoisted_2, [_unref(binding).entity.value.photoMain ? (_openBlock(), _createElementBlock("img", {
          key: 0,
          class: "img",
          src: _unref(binding).entity.value.photoMain,
          alt: ""
        }, null, 8, _hoisted_3)) : _createCommentVNode("", true), _createElementVNode("div", _hoisted_4, _toDisplayString(_unref(binding).entity.value.date), 1), _createElementVNode("div", {
          class: "description",
          innerHTML: _unref(binding).entity.value.description
        }, null, 8, _hoisted_5)])) : _createCommentVNode("", true)]),
        _: 1
      });
    };
  }

};