import { unref as _unref, createVNode as _createVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue";
import { Icon } from "@iconify/vue";
export default {
  __name: 'FavoritesIndicator',

  setup(__props) {
    return (_ctx, _cache) => {
      const _component_el_container = _resolveComponent("el-container");

      const _component_router_link = _resolveComponent("router-link");

      return _openBlock(), _createBlock(_component_router_link, {
        to: {
          name: 'favorites.representation'
        },
        class: "iconArea"
      }, {
        default: _withCtx(() => [_createVNode(_component_el_container, null, {
          default: _withCtx(() => [_createVNode(_unref(Icon), {
            icon: "ic:baseline-favorite-border",
            class: "icon"
          })]),
          _: 1
        })]),
        _: 1
      }, 8, ["to"]);
    };
  }

};