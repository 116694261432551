import { unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, withCtx as _withCtx, createBlock as _createBlock, createVNode as _createVNode, createSlots as _createSlots, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

const _withScopeId = n => (_pushScopeId("data-v-2b6450e3"), n = n(), _popScopeId(), n);

const _hoisted_1 = {
  key: 0,
  class: "gallery",
  id: "gallery"
};
const _hoisted_2 = {
  class: "main-slider__preview"
};
const _hoisted_3 = ["src"];
const _hoisted_4 = ["src"];
import { Icon } from "@iconify/vue";
import { Swiper, SwiperSlide } from 'swiper/vue';
import { Navigation, Thumbs } from 'swiper';
import 'swiper/css';
import 'swiper/css/navigation';
import { ref, computed, onMounted } from "vue";
import PhotoSwipeLightbox from 'photoswipe/lightbox';
import 'photoswipe/style.css';
export default {
  __name: 'ItemShowcase',
  props: {
    item: Object,
    singleImg: String
  },

  setup(__props) {
    const props = __props;
    const photos = computed(() => {
      const res = [];

      if (props?.singleImg) {
        res.push({
          src: props?.singleImg,
          w: 0,
          h: 0
        });
      } else {
        props.item?.photosInfo?.forEach(image => {
          res.push({
            "src": image.url,
            "width": image.width,
            "height": image.height
          });
        });
      }

      return res;
    }); // Создание нового массива для полноэкранной галереи

    const galleryOptions = computed(() => {
      if (!photos.value) {
        return null;
      }

      return {
        dataSource: photos.value,
        showHideAnimationType: 'fade',
        pswpModule: () => import('photoswipe'),
        secondaryZoomLevel: 3
      };
    }); // Инициализация галереи

    const lightbox = new PhotoSwipeLightbox(galleryOptions.value);
    onMounted(() => {
      lightbox.init();
    }); // Получение основного слайдера

    const mainSwiper = ref(null);

    const setMainSwiper = swiper => {
      mainSwiper.value = swiper;
      swiper.update();
    }; // Открытие галереи при клике на изображение в слайдере


    const openGallery = () => {
      const galleryIndex = mainSwiper.value.activeIndex - 1;
      const galleryLength = photos.value.length;
      lightbox.loadAndOpen(galleryIndex === -1 ? galleryIndex + galleryLength : galleryIndex);
    }; // Привязка thumbnails к основному слайдеру


    const thumbsSwiper = ref(null);

    const setThumbsSwiper = swiper => {
      thumbsSwiper.value = swiper;
      swiper.update();
    }; // Условная отрисовка стрелок управления


    const isMainButtonsVisible = computed(() => photos.value.length > 1);
    const isThumbButtonsVisible = computed(() => photos.value.length > 4); // Стили thumbnails

    const thumbSwiperStyleObject = {
      padding: isThumbButtonsVisible.value ? '0 45px' : ''
    }; // Стрелки для переключения слайдов

    const mainPrev = ref(null);
    const mainNext = ref(null);
    const thumbPrev = ref(null);
    const thumbNext = ref(null);
    return (_ctx, _cache) => {
      return _unref(photos) ? (_openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_unref(Swiper), {
        onSwiper: setMainSwiper,
        navigation: {
          prevEl: mainPrev.value,
          nextEl: mainNext.value
        },
        thumbs: {
          swiper: thumbsSwiper.value
        },
        modules: [_unref(Navigation), _unref(Thumbs)],
        loop: _unref(isMainButtonsVisible),
        class: "main-slider"
      }, _createSlots({
        default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(photos), image => {
          return _openBlock(), _createBlock(_unref(SwiperSlide), {
            key: image
          }, {
            default: _withCtx(() => [_createElementVNode("div", _hoisted_2, [_createElementVNode("a", {
              class: "btn-open-gallery",
              rel: "noreferrer",
              onClick: openGallery
            }, [_createElementVNode("img", {
              src: image.src,
              class: "img-fit"
            }, null, 8, _hoisted_3)])])]),
            _: 2
          }, 1024);
        }), 128))]),
        _: 2
      }, [_unref(isMainButtonsVisible) ? {
        name: "container-start",
        fn: _withCtx(() => [_createElementVNode("button", {
          class: "swiper-button swiper-button_prev",
          ref_key: "mainPrev",
          ref: mainPrev
        }, [_createVNode(_unref(Icon), {
          icon: "bxs:chevron-left",
          class: "img"
        })], 512)])
      } : undefined, _unref(isMainButtonsVisible) ? {
        name: "container-end",
        fn: _withCtx(() => [_createElementVNode("button", {
          class: "swiper-button swiper-button_next",
          ref_key: "mainNext",
          ref: mainNext
        }, [_createVNode(_unref(Icon), {
          icon: "bxs:chevron-right",
          class: "img"
        })], 512)])
      } : undefined]), 1032, ["navigation", "thumbs", "modules", "loop"]), _createElementVNode("div", {
        class: "bottom-slider",
        style: thumbSwiperStyleObject
      }, [_unref(isThumbButtonsVisible) ? (_openBlock(), _createElementBlock("button", {
        key: 0,
        class: "swiper-button swiper-button_prev",
        ref_key: "thumbPrev",
        ref: thumbPrev
      }, [_createVNode(_unref(Icon), {
        icon: "bxs:left-arrow-alt",
        class: "img"
      })], 512)) : _createCommentVNode("", true), _createVNode(_unref(Swiper), {
        onSwiper: setThumbsSwiper,
        "space-between": 20,
        modules: [_unref(Thumbs), _unref(Navigation)],
        navigation: {
          prevEl: thumbPrev.value,
          nextEl: thumbNext.value
        },
        "watch-slides-progress": true,
        "slides-per-view": 4,
        class: "thumbs-slider"
      }, {
        default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(photos), image => {
          return _openBlock(), _createBlock(_unref(SwiperSlide), {
            class: "thumbs-slider__slide",
            key: image.src
          }, {
            default: _withCtx(() => [_createElementVNode("img", {
              src: image.src,
              style: {
                "max-width": "100%"
              }
            }, null, 8, _hoisted_4)]),
            _: 2
          }, 1024);
        }), 128))]),
        _: 1
      }, 8, ["modules", "navigation"]), _unref(isThumbButtonsVisible) ? (_openBlock(), _createElementBlock("button", {
        key: 1,
        class: "swiper-button swiper-button_next",
        ref_key: "thumbNext",
        ref: thumbNext
      }, [_createVNode(_unref(Icon), {
        icon: "bxs:right-arrow-alt",
        class: "img"
      })], 512)) : _createCommentVNode("", true)])])) : _createCommentVNode("", true);
    };
  }

};