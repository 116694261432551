import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue";
import { ref, watch } from "vue";
export default {
  __name: 'FormGenerator',
  props: {
    schema: Array,
    modelValue: Object
  },
  emits: ['update:modelValue'],

  setup(__props, {
    expose,
    emit: emits
  }) {
    const props = __props;
    const refForm = ref();

    const validate = async () => {
      let validation;

      try {
        validation = await refForm.value.validate();
      } catch {
        /* ignore */
      }

      return validation;
    };

    expose({
      validate
    });
    const form = ref({});
    watch([form], ([f]) => {
      emits('update:modelValue', f);
    }, {
      immediate: true
    });
    return (_ctx, _cache) => {
      const _component_el_input = _resolveComponent("el-input");

      const _component_el_form_item = _resolveComponent("el-form-item");

      const _component_el_form = _resolveComponent("el-form");

      return _openBlock(), _createBlock(_component_el_form, {
        ref_key: "refForm",
        ref: refForm,
        class: "formGenerator",
        model: form.value
      }, {
        default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(props.schema, schem => {
          return _openBlock(), _createBlock(_component_el_form_item, {
            key: schem.id,
            label: schem.title,
            prop: schem.id,
            rules: [{
              required: schem.isRequired,
              message: 'Обязательное поле',
              trigger: 'blur'
            }]
          }, {
            default: _withCtx(() => [_createVNode(_component_el_input, {
              modelValue: form.value[schem.id],
              "onUpdate:modelValue": $event => form.value[schem.id] = $event
            }, null, 8, ["modelValue", "onUpdate:modelValue"])]),
            _: 2
          }, 1032, ["label", "prop", "rules"]);
        }), 128))]),
        _: 1
      }, 8, ["model"]);
    };
  }

};