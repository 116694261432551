import {computed} from "vue";
import {usePermissions} from "@/features/permissions/usePermissions.js";

const permissions = usePermissions()
const baseElements = [
  {
    title: 'Каталог',
    icon: 'ic:outline-shopping-cart',
    link: {name: 'catalog'},
  },
  {
    title: 'Дашборд',
    icon: 'ic:outline-space-dashboard',
    link: {name: 'dashboard'},
  },
  {
    title: 'Заказы',
    icon: 'bx:basket',
    link: {name: 'orders.representation'},
  },
  {
    title: 'Документы',
    icon: 'bx:file-blank',
    link: {name: 'documents.representation'},
  },
  {
    title: 'Управление',
    icon: 'bx:message-square-edit',
    link: '#',
    children: [
      {
        title: 'Юр. лица',
        icon: 'bx:briefcase',
        link: {name: 'legals.representation'},
      },
      {
        title: 'Мой профиль',
        icon: 'bx:user',
        link: {name: 'users.detailed', params: {id: 'me'}},
      },
      {
        title: 'Пользователи',
        icon: 'bx:group',
        link: {name: 'users.representation'},
      },
      {
        title: 'Рассылка',
        icon: 'bx:envelope',
        link: {name: 'mailings.edit'},
      },
    ],
  },
]
const retailElements = computed(() => {
  const retail = {
    title: 'Ретейл',
    icon: 'bx:store',
    link: '#',
    children: [
      {
        title: 'Брендирование',
        icon: 'bx:brush',
        link: {name: 'retail.branding'},
      },
      {
        title: 'Отключить Клиент рядом',
        icon: 'bx:exit',
        link: {name: 'retail.disable'},
      }
    ],
  }


  if (permissions.has('margins')) {
    retail.children[2] = retail.children[1]
    retail.children[1] = retail.children[0]
    retail.children[0] = {
      title: 'Ценообразование',
      icon: 'bx:money',
      link: {name: 'retail.margins.representation'},
    }
  }

  return [retail]
})

const elements = computed( () => {
  const res = []
  res.push(...baseElements)
  if (permissions.has('retail')) {
    res.push(...retailElements.value)
  }
  return res
})


export const useNavMenu = () => {
  return {
    elements,
  }
}
