export const CONFIG = {

  auth: {
    loginLogoPath: null,
    register: {
      subtitle: 'Заявку рассмотрит наш менеджер и отправит Вам на почту результат проверки.',
      // hint: 'Проблемы с регистрацией? Напишите нам на&nbsp;<a href="mailto:b2b@example.com" target="_blank">b2b@example.com</a>',
      hint: null,
      checkboxes: [
        /** ID должен начинаться с префикса cb_ */
        {
          id: 'cb_privacy',
          html: 'Согласен на&nbsp;<a href="/upload/b2b_load/policy.html" target="_blank">обработку персональных данных</a>'
        },
      ],
    },
  },

  layoutStandart: {
    headerContacts: [
    ],
    headerLogoPath: require('@/assets/config/logo.png'),
  },

  navWatcher: {
    titleSuffix: ' — seven.market',
  },

  catalog: {
    defaultItemView: 'grid', // 'list' | 'grid'
  },

  print: {
    logoPath: require('@/assets/config/logo_print.png'),
  },

  banners: {
    height: '400px',
  },

  global: {
    country: 'RU',
  },
}
