import { unref as _unref, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

const _withScopeId = n => (_pushScopeId("data-v-b5cecd26"), n = n(), _popScopeId(), n);

const _hoisted_1 = {
  key: 0,
  class: "availability yes"
};
const _hoisted_2 = {
  class: "text"
};
const _hoisted_3 = {
  key: 1,
  class: "availability no"
};
const _hoisted_4 = {
  class: "text"
};
import { computed } from "vue";
import { Icon } from "@iconify/vue";
export default {
  __name: 'ItemAvailability',
  props: ['availability', 'short'],

  setup(__props) {
    const props = __props;
    const text = computed(() => {
      return props?.short ? {
        yes: 'Да',
        no: 'Нет'
      } : {
        yes: 'В наличии',
        no: 'Под заказ'
      };
    });
    return (_ctx, _cache) => {
      return props?.availability ? (_openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_unref(Icon), {
        icon: "ci:check-bold",
        class: "icon"
      }), _createElementVNode("div", _hoisted_2, _toDisplayString(_unref(text).yes), 1)])) : (_openBlock(), _createElementBlock("div", _hoisted_3, [_createVNode(_unref(Icon), {
        icon: "fluent:call-48-filled",
        class: "icon"
      }), _createElementVNode("div", _hoisted_4, _toDisplayString(_unref(text).no), 1)]));
    };
  }

};