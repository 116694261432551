import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

const _withScopeId = n => (_pushScopeId("data-v-4526de82"), n = n(), _popScopeId(), n);

const _hoisted_1 = {
  class: "content"
};
export function render(_ctx, _cache) {
  const _component_router_view = _resolveComponent("router-view");

  const _component_el_container = _resolveComponent("el-container");

  return _openBlock(), _createBlock(_component_el_container, {
    direction: "vertical",
    style: {
      "min-height": "100vh"
    },
    class: "layoutPrint"
  }, {
    default: _withCtx(() => [_createElementVNode("div", _hoisted_1, [_createVNode(_component_router_view, {
      class: "routerView"
    })])]),
    _: 1
  });
}