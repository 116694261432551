import { unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

const _withScopeId = n => (_pushScopeId("data-v-62d85f62"), n = n(), _popScopeId(), n);

const _hoisted_1 = {
  key: 0,
  class: "banners"
};
const _hoisted_2 = ["href"];
const _hoisted_3 = ["src"];
const _hoisted_4 = ["src"];
import { onMounted, ref, computed, watch } from "vue";
import { useEntity } from "@/features/api/useEntity.js";
import { Swiper, SwiperSlide } from 'swiper/vue';
import { Navigation, Pagination, Autoplay } from "swiper";
import 'swiper/css';
import "swiper/css/navigation";
import "swiper/css/pagination";
import { CONFIG } from "@/config.js";
export default {
  __name: 'BannersSlider',

  setup(__props) {
    const banners = ref([]); // TODO: Отключение уведомлений об ошибке временно, пока на бэкенде не готово это АПИ.

    const entityBanner = useEntity('Banner', {
      showNotificationOnError: false
    });
    onMounted(async () => {
      const res = await entityBanner.list();
      banners.value = res.result;
    });
    const bannersSlider = ref('');

    const setBannersSlider = swiper => {
      bannersSlider.value = swiper;
    };

    const isOneSlide = computed(() => banners.value?.length === 1);
    watch(isOneSlide, currentValue => {
      if (currentValue === true) {
        bannersSlider.value.enabled = false;
      }
    });
    const pagination = {
      clickable: true
    };
    return (_ctx, _cache) => {
      const _component_router_link = _resolveComponent("router-link");

      return banners.value?.length > 0 ? (_openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_unref(Swiper), {
        onSwiper: setBannersSlider,
        class: "banners-slider",
        modules: [_unref(Navigation), _unref(Pagination), _unref(Autoplay)],
        navigation: !_unref(isOneSlide),
        pagination: !_unref(isOneSlide) ? pagination : false,
        autoplay: {
          delay: 8000,
          disableOnInteraction: false
        },
        loop: !_unref(isOneSlide)
      }, {
        default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(banners.value, item => {
          return _openBlock(), _createBlock(_unref(SwiperSlide), {
            key: item.id
          }, {
            default: _withCtx(() => [item.link.substr(0, 4) === 'http' ? (_openBlock(), _createElementBlock("a", {
              key: 0,
              class: "link",
              href: item.link
            }, [_createElementVNode("img", {
              class: "image",
              src: item.img,
              style: _normalizeStyle(_unref(CONFIG).banners)
            }, null, 12, _hoisted_3)], 8, _hoisted_2)) : (_openBlock(), _createBlock(_component_router_link, {
              key: 1,
              to: item.link.startsWith('/') ? item.link : '/' + item.link
            }, {
              default: _withCtx(() => [_createElementVNode("img", {
                class: "image",
                src: item.img,
                style: _normalizeStyle(_unref(CONFIG).banners)
              }, null, 12, _hoisted_4)]),
              _: 2
            }, 1032, ["to"]))]),
            _: 2
          }, 1024);
        }), 128))]),
        _: 1
      }, 8, ["modules", "navigation", "pagination", "loop"])])) : _createCommentVNode("", true);
    };
  }

};