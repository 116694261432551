import { unref as _unref, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue";
import CategoryTreeAside from "@/features/catalog/itemsRepresentation/CategoryTreeAside.vue";
import PageHeader from "@/features/ui/PageHeader.vue";
import { computed, watch } from "vue";
import ItemActions from "@/features/item/ItemActions.vue";
import ItemInfo from "@/features/item/ItemInfo.vue";
import PageContainer from "@/features/layoutStandart/PageContainer.vue";
import ItemShowcase from "@/features/item/ItemShowcase";
import { statePageItem } from "@/features/item/statePageItem.js";
import ItemRelateds from "@/features/item/ItemRelateds.vue";
import { useUrlBindingId } from "@/features/urlBinding/useUrlBindingId.js";
import { useNavWatcher } from "@/features/navWatcher/useNavWatcher.js";
export default {
  __name: 'PageItem',

  setup(__props) {
    const state = statePageItem(useUrlBindingId('Item'));
    const singleImg = computed(() => {
      if (state.selectedItemOffer.value?.photo && state.item.value?.offers?.length > 2) {
        return state.selectedItemOffer.value?.photo;
      }

      return null;
    });
    watch([state.item], ([item]) => {
      if (item?.title) useNavWatcher().title.value = item.title;
    });
    const curCatalogCategory = computed(() => {
      const id = state.item?.value?.category;
      return state.categories.value.find(c => c.id === id);
    });
    const breadcrumb = computed(() => {
      let res = [];
      let babyMammoth = curCatalogCategory?.value;
      /* eslint-disable-next-line */

      while (true) {
        if (!babyMammoth) break;
        res = [{
          text: babyMammoth?.title,
          to: {
            name: 'catalog.items',
            params: {
              categoryId: babyMammoth?.id
            }
          }
        }, ...res];
        let mama = state.categories.value.find(c => babyMammoth?.parent === c.id) ?? null;
        if (mama === null) break;
        babyMammoth = mama;
      }

      return [{
        text: 'Каталог',
        to: {
          name: 'catalog'
        }
      }, ...res];
    });
    return (_ctx, _cache) => {
      const _component_el_empty = _resolveComponent("el-empty");

      const _component_el_container = _resolveComponent("el-container");

      const _component_el_main = _resolveComponent("el-main");

      const _directive_loading = _resolveDirective("loading");

      return _openBlock(), _createBlock(PageContainer, {
        class: _normalizeClass(_ctx.$style.itemView)
      }, {
        aside: _withCtx(() => [_createVNode(CategoryTreeAside, {
          expandedIds: [_unref(state).item.value?.category]
        }, null, 8, ["expandedIds"])]),
        default: _withCtx(() => [_createVNode(PageHeader, {
          title: _unref(state).item.value?.title ?? 'x',
          loading: _unref(state).itemBind.loading.value,
          breadcrumb: _unref(breadcrumb),
          style: {
            "margin-bottom": "15px"
          }
        }, null, 8, ["title", "loading", "breadcrumb"]), _withDirectives((_openBlock(), _createBlock(_component_el_main, {
          class: _normalizeClass(_ctx.$style.main)
        }, {
          default: _withCtx(() => [_createVNode(_component_el_container, {
            class: _normalizeClass(_ctx.$style.top)
          }, {
            default: _withCtx(() => [_unref(state).item.value && _unref(state).item.value?.photosInfo && _unref(state).item.value?.photosInfo[0].url ? (_openBlock(), _createBlock(_unref(ItemShowcase), {
              key: 0,
              style: {
                "flex": "1"
              },
              item: _unref(state).item.value,
              singleImg: _unref(singleImg)
            }, null, 8, ["item", "singleImg"])) : (_openBlock(), _createBlock(_component_el_empty, {
              key: 1,
              style: {
                "flex": "1"
              },
              description: "Нет фото"
            })), _createVNode(ItemActions, {
              style: {
                "flex": "1"
              },
              item: _unref(state).item.value
            }, null, 8, ["item"])]),
            _: 1
          }, 8, ["class"]), _createVNode(ItemInfo), (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(state).item.value?.relatedItems, relateds => {
            return _openBlock(), _createBlock(ItemRelateds, {
              key: relateds.title,
              title: relateds.title,
              items: relateds.items
            }, null, 8, ["title", "items"]);
          }), 128))]),
          _: 1
        }, 8, ["class"])), [[_directive_loading, _unref(state).itemBind.loading.value]])]),
        _: 1
      }, 8, ["class"]);
    };
  }

};