import { unref as _unref, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, withCtx as _withCtx, resolveComponent as _resolveComponent, resolveDirective as _resolveDirective, createBlock as _createBlock, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

const _withScopeId = n => (_pushScopeId("data-v-4fe219ec"), n = n(), _popScopeId(), n);

const _hoisted_1 = ["src"];
const _hoisted_2 = {
  class: "description"
};
const _hoisted_3 = {
  class: ""
};
const _hoisted_4 = {
  class: "a"
};
const _hoisted_5 = {
  class: "title"
};
const _hoisted_6 = {
  class: "price"
};
const _hoisted_7 = {
  key: 1,
  class: "offersNumber"
};
import { computed } from "vue";
import AppCard from "@/features/ui/AppCard.vue";
import ItemAvailability from "@/features/ui/ItemAvailability.vue";
import { usePriceBin } from "@/features/prices/usePriceBin.js";
import { getNumEnding, moneyFormat } from "@/features/utils/utils.js";
export default {
  __name: 'ItemCard',
  props: {
    item: Object
  },

  setup(__props) {
    const props = __props;
    const price = computed(() => {
      return moneyFormat(usePriceBin().get(props?.item?.defaultPrice));
    });
    const loading = computed(() => {
      return !props?.item;
    });
    const routerTo = computed(() => {
      if (!props?.item?.id) return {};
      return {
        name: 'item.detailed',
        params: {
          id: props?.item?.id
        }
      };
    });
    const offersNumber = computed(() => {
      if (!(props?.item?.offers?.length > 1)) return null;
      const n = props?.item?.offers?.length;
      return n + " " + getNumEnding(n, ['вариант', 'варианта', 'вариантов']);
    });
    const availability = computed(() => {
      for (const offer of props?.item?.offers ?? []) {
        if (offer?.balance > 0) {
          return true;
        }
      }

      return false;
    });
    return (_ctx, _cache) => {
      const _component_router_link = _resolveComponent("router-link");

      const _directive_loading = _resolveDirective("loading");

      return _withDirectives((_openBlock(), _createBlock(_component_router_link, {
        to: _unref(routerTo)
      }, {
        default: _withCtx(() => [_createVNode(AppCard, {
          class: "itemCard",
          noTitle: true
        }, {
          default: _withCtx(() => [props?.item?.photoMain ? (_openBlock(), _createElementBlock("img", {
            key: 0,
            src: props.item?.photoMain,
            class: "img"
          }, null, 8, _hoisted_1)) : _createCommentVNode("", true), _createElementVNode("div", _hoisted_2, [_createElementVNode("div", _hoisted_3, [_createVNode(ItemAvailability, {
            availability: _unref(availability)
          }, null, 8, ["availability"])])]), _createElementVNode("div", _hoisted_4, "Артикул: " + _toDisplayString(props.item?.a), 1), _createElementVNode("div", _hoisted_5, _toDisplayString(props.item?.title), 1), _createElementVNode("div", _hoisted_6, _toDisplayString(_unref(price)), 1), _unref(offersNumber) ? (_openBlock(), _createElementBlock("div", _hoisted_7, _toDisplayString(_unref(offersNumber)), 1)) : _createCommentVNode("", true)]),
          _: 1
        })]),
        _: 1
      }, 8, ["to"])), [[_directive_loading, _unref(loading)]]);
    };
  }

};