import {ref} from "vue";

const priceType = ref('priceRoz')

export const usePriceBin = () => {
  return {
    priceType,

    get: (priceSet) => {
      if (priceSet && priceSet[priceType.value]) return priceSet[priceType.value]
      return null
    },
  }
}
