import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, unref as _unref, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

const _withScopeId = n => (_pushScopeId("data-v-f7ad9016"), n = n(), _popScopeId(), n);

const _hoisted_1 = {
  class: "bundlesPrintable"
};
const _hoisted_2 = ["src"];
const _hoisted_3 = {
  class: "description"
};
const _hoisted_4 = {
  class: "itemTitle"
};
const _hoisted_5 = {
  class: "offerTitle"
};
const _hoisted_6 = {
  class: "a"
};
const _hoisted_7 = {
  class: "numbers"
};
const _hoisted_8 = {
  class: "price"
};
const _hoisted_9 = {
  class: "amount"
};
const _hoisted_10 = {
  class: "total"
};
import { usePriceBin } from "@/features/prices/usePriceBin.js";
import { moneyFormat } from "@/features/utils/utils.js";
export default {
  __name: 'BundlesPrintable',
  props: {
    bundles: Array
  },

  setup(__props) {
    const props = __props;
    const priceBin = usePriceBin();

    const thisMoneyFormat = (money, options) => moneyFormat(money, options);

    const offer = bundle => {
      if (!bundle) return null;
      return bundle.item.offers.find(offer => "" + offer.id === bundle.itemOfferId);
    };

    return (_ctx, _cache) => {
      return _openBlock(), _createElementBlock("div", _hoisted_1, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(props.bundles, bundle => {
        return _openBlock(), _createElementBlock("div", {
          class: "bundle",
          key: bundle.id
        }, [_createElementVNode("img", {
          src: bundle.item.photoMain,
          class: "photo"
        }, null, 8, _hoisted_2), _createElementVNode("div", _hoisted_3, [_createElementVNode("div", _hoisted_4, _toDisplayString(bundle.item?.title ?? 'Нет названия'), 1), _createElementVNode("div", _hoisted_5, _toDisplayString(offer(bundle)?.title ?? '(неизвестно)'), 1), _createElementVNode("div", _hoisted_6, "Артикул: " + _toDisplayString(bundle.item.a), 1), _createElementVNode("div", _hoisted_7, [_createElementVNode("div", _hoisted_8, "Цена за шт: " + _toDisplayString(thisMoneyFormat(_unref(priceBin).get(offer(bundle).price))), 1), _createElementVNode("div", _hoisted_9, "Количество: " + _toDisplayString(bundle.amount), 1), _createElementVNode("div", _hoisted_10, "Сумма: " + _toDisplayString(thisMoneyFormat(_unref(priceBin).get(offer(bundle).price), {
          valueMultiplier: bundle.amount
        })), 1)])])]);
      }), 128))]);
    };
  }

};