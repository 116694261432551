import { createVNode as _createVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue";
import AppHeader from "@/features/layoutStandart/AppHeader.vue";
import AppFooter from "@/features/layoutStandart/AppFooter.vue";
import AsideMenu from "@/features/layoutStandart/AsideMenu.vue";
export default {
  __name: 'LayoutDefault',

  setup(__props) {
    return (_ctx, _cache) => {
      const _component_router_view = _resolveComponent("router-view");

      const _component_el_container = _resolveComponent("el-container");

      return _openBlock(), _createBlock(_component_el_container, {
        style: {
          "min-height": "100vh"
        },
        direction: "vertical"
      }, {
        default: _withCtx(() => [_createVNode(AppHeader), _createElementVNode("div", {
          class: _normalizeClass(_ctx.$style.content)
        }, [_createVNode(AsideMenu), _createVNode(_component_router_view)], 2), _createVNode(AppFooter, {
          style: {
            "padding-top": "30px"
          }
        })]),
        _: 1
      });
    };
  }

};