import { unref as _unref, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, withCtx as _withCtx } from "vue";
import { onMounted, ref, computed } from "vue";
import { useEntity } from "@/features/api/useEntity.js";
import { useRouter } from "vue-router";
export default {
  __name: 'CategoryTreeAside',
  props: ['expandedIds'],

  setup(__props) {
    const props = __props;
    const router = useRouter();
    const entityCatalogCategory = useEntity('CatalogCategory');
    const categories = ref([]);
    onMounted(async () => {
      const res = await entityCatalogCategory.list();
      categories.value = res.result;
    });
    const data = computed(() => {
      const res = [];
      let unprocessed = [...categories.value];

      for (const cat of unprocessed) {
        if (cat?.parent === null) {
          cat.children = [];
          res.push(cat);
          unprocessed = unprocessed.filter(c => c.id !== cat.id);
        }
      }

      const construct = node => {
        for (const cat of unprocessed) {
          if (cat?.parent === node.id) {
            cat.children = [];
            node.children.push(cat);
            unprocessed = unprocessed.filter(c => c.id !== cat.id);
          }
        }

        for (const child of node.children) {
          construct(child);
        }
      };

      for (let parentCategoryI = 0; parentCategoryI < res.length; parentCategoryI++) {
        construct(res[parentCategoryI]);
      }

      return res;
    });
    const propNames = {
      children: 'children',
      label: 'title'
    };

    const onNodeClick = node => {
      router.push({
        name: 'catalog.items',
        params: {
          categoryId: node.id
        }
      });
    };

    return (_ctx, _cache) => {
      const _component_el_tree = _resolveComponent("el-tree");

      const _component_el_aside = _resolveComponent("el-aside");

      return _openBlock(), _createBlock(_component_el_aside, {
        class: _normalizeClass(_ctx.$style.categoryTreeView)
      }, {
        default: _withCtx(() => [props.expandedIds[0] ? (_openBlock(), _createBlock(_component_el_tree, {
          key: 0,
          data: _unref(data),
          props: propNames,
          "node-key": "id",
          "default-expanded-keys": props.expandedIds ?? [],
          "current-node-key": String(props.expandedIds[0]),
          onNodeClick: onNodeClick
        }, null, 8, ["data", "default-expanded-keys", "current-node-key"])) : _createCommentVNode("", true)]),
        _: 1
      }, 8, ["class"]);
    };
  }

};