import { createVNode as _createVNode, unref as _unref, resolveDirective as _resolveDirective, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createBlock as _createBlock, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, withCtx as _withCtx } from "vue";
const _hoisted_1 = {
  key: 0,
  style: {
    "height": "100px",
    "width": "100px"
  }
};
import PageHeader from "@/features/ui/PageHeader.vue";
import CatalogCategoryCard from "@/features/catalog/categoriesRepresentation/CatalogCategoryCard.vue";
import { computed, onMounted, ref } from "vue";
import { useEntity } from "@/features/api/useEntity.js";
import PageContainer from "@/features/layoutStandart/PageContainer.vue";
import ItemSetsDisplay from "@/features/itemSet/ItemSetsDisplay.vue";
export default {
  __name: 'PageCatalogCategories',

  setup(__props) {
    const entityCatalogCategory = useEntity('CatalogCategory');
    const categories = ref([]);
    const columns = computed(() => 4);
    onMounted(async () => {
      const res = await entityCatalogCategory.list();
      categories.value = res.result;
    });
    const rootCategories = computed(() => {
      return categories.value.filter(cat => cat.parent === null);
    });

    const childrenOf = parent => {
      return categories.value.filter(cat => cat.parent === parent.id);
    };

    const categoriesForCol = col => {
      const res = [];

      for (let i = col - 1; i < rootCategories.value.length; i += columns.value) {
        res.push(rootCategories.value[i]);
      }

      return res;
    };

    const loading = computed(() => entityCatalogCategory.loading.value);
    return (_ctx, _cache) => {
      const _directive_loading = _resolveDirective("loading");

      return _openBlock(), _createBlock(PageContainer, null, {
        default: _withCtx(() => [_createVNode(PageHeader, {
          style: {
            "margin-bottom": "5px"
          }
        }), _unref(loading) ? _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_1, null, 512)), [[_directive_loading, _unref(loading)]]) : _createCommentVNode("", true), _createVNode(ItemSetsDisplay), _createElementVNode("div", {
          class: _normalizeClass(_ctx.$style.catalogCards)
        }, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(columns), col => {
          return _openBlock(), _createElementBlock("div", {
            class: _normalizeClass(_ctx.$style.col),
            key: col
          }, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(categoriesForCol(col), rootCat => {
            return _openBlock(), _createBlock(CatalogCategoryCard, {
              key: rootCat.id,
              parent: rootCat,
              children: childrenOf(rootCat)
            }, null, 8, ["parent", "children"]);
          }), 128))], 2);
        }), 128))], 2)]),
        _: 1
      });
    };
  }

};