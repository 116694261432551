import { unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

const _withScopeId = n => (_pushScopeId("data-v-70023b79"), n = n(), _popScopeId(), n);

const _hoisted_1 = {
  class: "userAsideMenu2"
};
const _hoisted_2 = {
  class: "elements"
};
import AsideMenuElement from "@/features/layoutStandart/AsideMenuElement.vue";
import { useNavMenu } from "@/features/layoutStandart/useNavMenu.js";
export default {
  __name: 'AsideMenu',

  setup(__props) {
    const navMenu = useNavMenu();
    return (_ctx, _cache) => {
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(navMenu).elements.value, element => {
        return _openBlock(), _createBlock(AsideMenuElement, {
          key: element.title,
          title: element.title,
          icon: element.icon,
          link: element.link,
          children: element?.children ?? []
        }, null, 8, ["title", "icon", "link", "children"]);
      }), 128))])]);
    };
  }

};