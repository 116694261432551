import { createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

const _withScopeId = n => (_pushScopeId("data-v-2c6843cb"), n = n(), _popScopeId(), n);

const _hoisted_1 = {
  class: "header"
};
const _hoisted_2 = {
  class: "body"
};
const _hoisted_3 = {
  class: "row"
};
const _hoisted_4 = {
  class: "column left"
};
const _hoisted_5 = {
  class: "column right"
};
import PageHeader from "@/features/ui/PageHeader.vue";
import LatestOrders from "@/features/orders/representation/LatestOrders.vue";
import NewsComponent from "@/features/news/NewsComponent.vue";
import ManagerInfo from "@/features/managerInfo/ManagerInfo.vue";
import PageContainer from "@/features/layoutStandart/PageContainer.vue";
import BannersSlider from "@/features/banners/BannersSlider.vue";
export default {
  __name: 'PageDashboard',

  setup(__props) {
    return (_ctx, _cache) => {
      return _openBlock(), _createBlock(PageContainer, {
        class: "cabinetDashboard"
      }, {
        default: _withCtx(() => [_createElementVNode("div", _hoisted_1, [_createVNode(PageHeader, {
          title: "Рады видеть Вас снова"
        })]), _createElementVNode("div", _hoisted_2, [_createElementVNode("div", _hoisted_3, [_createElementVNode("div", _hoisted_4, [_createVNode(BannersSlider), _createVNode(LatestOrders)]), _createElementVNode("div", _hoisted_5, [_createVNode(ManagerInfo), _createVNode(NewsComponent)])])])]),
        _: 1
      });
    };
  }

};