import PageDashboard from "@/features/dashboard/PageDashboard.vue";

export const dashboard = [
    {
        path: '/dashboard',
        name: 'dashboard',
        component: PageDashboard,
        meta: {
            navTitle: 'Дашборд'
        }
    },
]
