import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

const _withScopeId = n => (_pushScopeId("data-v-9bec7f36"), n = n(), _popScopeId(), n);

const _hoisted_1 = {
  class: "itemSetDisplay"
};
import { ref, onMounted } from "vue";
import { useEntity } from "@/features/api/useEntity.js";
export default {
  __name: 'ItemSetsDisplay',

  setup(__props) {
    const cssClasses = ['yellow'];
    const itemSets = ref([]);
    const entityItemSet = useEntity('ItemSet');
    onMounted(async () => {
      const res = await entityItemSet.list();
      itemSets.value = res.result;
    });
    return (_ctx, _cache) => {
      const _component_router_link = _resolveComponent("router-link");

      return _openBlock(), _createElementBlock("div", _hoisted_1, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(itemSets.value, (itemSet, i) => {
        return _openBlock(), _createBlock(_component_router_link, {
          to: {
            name: 'itemSet.representation',
            params: {
              id: itemSet.id
            }
          },
          key: itemSet.id,
          class: _normalizeClass(["itemSet gradient", cssClasses[i % cssClasses.length]])
        }, {
          default: _withCtx(() => [_createElementVNode("div", null, _toDisplayString(itemSet.title), 1)]),
          _: 2
        }, 1032, ["to", "class"]);
      }), 128))]);
    };
  }

};