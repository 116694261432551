import { renderSlot as _renderSlot, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createSlots as _createSlots, openBlock as _openBlock, createBlock as _createBlock } from "vue";
export default {
  __name: 'AppCard',
  props: ['noTitle'],

  setup(__props) {
    const props = __props;
    return (_ctx, _cache) => {
      const _component_el_card = _resolveComponent("el-card");

      return _openBlock(), _createBlock(_component_el_card, null, _createSlots({
        default: _withCtx(() => [_renderSlot(_ctx.$slots, "default")]),
        _: 2
      }, [props.noTitle !== true ? {
        name: "header",
        fn: _withCtx(() => [_createElementVNode("div", {
          class: _normalizeClass(_ctx.$style.cardHeader)
        }, [_createElementVNode("div", {
          class: _normalizeClass(_ctx.$style.title)
        }, [_renderSlot(_ctx.$slots, "title")], 2), _createElementVNode("div", {
          class: _normalizeClass(_ctx.$style.actions)
        }, [_renderSlot(_ctx.$slots, "actions")], 2)], 2), _createElementVNode("div", {
          class: _normalizeClass(_ctx.$style.cardSubHeader)
        }, [_renderSlot(_ctx.$slots, "subTitle")], 2)])
      } : undefined]), 1024);
    };
  }

};