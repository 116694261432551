import {computed, ref, unref, watch} from "vue";
import {useEntity} from "@/features/api/useEntity.js";
import {useRouter, useRoute} from "vue-router";
import {useUrlBindingItemSorts} from "@/features/catalog/itemsRepresentation/useUrlBindingItemSorts.js";
import {useUrlBindingItemFilters} from "@/features/catalog/itemsRepresentation/useUrlBindingItemFilters.js";
import {CONFIG} from "@/config.js";


export function statePageCatalogItems () {
  const public_ = {}

  const router = useRouter();
  const route = useRoute();


  //region Фильтрация
  const urlBindingFiler = useUrlBindingItemFilters()
  public_.activeFilters = urlBindingFiler.filter
  public_.possibleFilters = ref(null)

  public_.onFilterSelected = (f) => {
    if (unref(f).bitrix) urlBindingFiler.filter.value = f.bitrix
  }
  //endregion


  //region Сортировка
  const urlBindingSort = useUrlBindingItemSorts()
  public_.activeSorts = urlBindingSort.sort
  public_.possibleSorts = ref(null)

  public_.onSortSelected = (richSort) => {
    if (unref(richSort)?.bitrix) urlBindingSort.sort.value = richSort.bitrix
  }
  //endregion


  //region Пагинация
  const hash = ref(route.hash === '#')

  public_.pageSizes = [10, 25, 50, 100, 200]
  public_.totalElements = ref(0)

  public_.currentPage = computed(() => Number(route?.query?.page ?? 1))
  public_.currentSize = computed(() => Number(route?.query?.size ?? public_.pageSizes[1]))

  public_.paginationMode = ref('init') // 'init' | 'page' | 'more'
  const morePage = ref(1)

  public_.onPageChange = (newPage) => {
    const q = route.query
    q.page = newPage
    hash.value = !hash.value
    router.replace({
      path: route.path,
      query: q,
      hash: hash.value ? '#' : '', // Иначе vue router не перезагружает страницу
    }).catch(()=>{})
  }

  public_.onSizeChange = (newSize) => {
    const q = route.query
    q.size = newSize
    hash.value = !hash.value
    router.replace({
      path: route.path,
      query: q,
      hash: hash.value ? '#' : '', // Иначе vue router не перезагружает страницу
    }).catch(()=>{})
  }

  public_.onMoreClick = () => {
    if (public_.paginationMode.value === 'init') public_.paginationMode.value = 'more'
    if (public_.paginationMode.value !== 'more') return
    morePage.value++
    public_.loadItems()
  }

  watch([public_.currentPage], ([p]) => {
    if (Number(p) !== 1) {
      public_.paginationMode.value = 'page'
    } else if (public_.paginationMode.value === 'page') {
      public_.paginationMode.value = 'init'
    }
  }, {immediate: true})

  public_.canLoadMore = computed(() => {
    return public_.items.value.length < public_.totalElements.value
  })
  //endregion


  //region Категории
  const entityCatalogCategory = useEntity('CatalogCategory')

  public_.categories = ref([])

  public_.loadCategories = async () => {
    const res = await entityCatalogCategory.list()
    public_.categories.value = res.result
  }

  public_.categoryId = computed(() => {
    return route.params?.categoryId ?? null
  })

  public_.curCatalogCategory = computed(() => {
    return public_.categories.value.find((category) => category.id === public_.categoryId.value) ?? null
  })
  //endregion


  //region Товары
  const entityItem = useEntity('Item')

  public_.items = ref([])
  public_.loadItems = () => {
    const filter_ = unref(public_.activeFilters)
    const page_ = public_.paginationMode.value === 'more' ? unref(morePage) : unref(public_.currentPage)
    const size_ = unref(public_.size)
    const categoryId_ = unref(public_.categoryId)
    const sort_ = unref(public_.activeSorts)

    if (!categoryId_) return

    const data = {
      filter: {
        category: categoryId_,
      },
    }
    if (filter_) data.filter = {
      ...filter_,
      ...data.filter,
    }
    if (page_) data.page = page_
    if (size_) data.size = size_
    else data.size = public_.currentSize.value ?? 25
    if (sort_) data.sort = sort_
    entityItem.list(data).then((res) => {
      public_.totalElements.value = Number(res.count)
      public_.possibleFilters.value = res.filters.filter((f) => {
        if (f.type === 'string') return false
        if (f.values?.length === 0) return false
        return true
      })
      public_.possibleSorts.value = res.sorts

      if (public_.paginationMode.value === 'more') {
        public_.items.value = [...public_.items.value, ...res.result]
      } else {
        public_.items.value = res.result
      }
    })
  }

  public_.loading = computed(() => {
    const c = entityCatalogCategory.loading.value
    const i = entityItem.loading.value
    return {
      catalogCategory: c,
      item: i,
    }
  })

  watch([
    public_.activeFilters,
    public_.currentPage,
    public_.currentSize,
    public_.categoryId,
    public_.activeSorts,
  ], () => {
    public_.loadItems()
  }, {immediate: true})
  //endregion

  //region Отображение (layout)
  public_.itemsShow = ref(CONFIG.catalog.defaultItemView)
  //endregion

  return public_
}
