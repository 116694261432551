import {ref, watch} from "vue"
import {useBasket} from "@/features/baskets/useBasket.js";

const basketModule = useBasket()

const bufferAmounts = ref({})

watch([basketModule.bundlesOfCurrentBasket], async ([bundlesOfCurrentBasket]) => {
  for (const b of bundlesOfCurrentBasket) {
    bufferAmounts.value[b.id] = b.amount
  }
}, {immediate: true})

const bundleByBundleId = (bundleId) => {
  return basketModule.bundlesOfCurrentBasket.value?.find((b) => b.id === bundleId) ?? null
}


const bufferHasChanges = (bundleId) => {
  return bufferAmounts.value[bundleId] !== bundleByBundleId(bundleId)?.amount
}

const confirmBuffer = async (bundleId) => {
  const amount = bufferAmounts.value[bundleId]
  if (amount <= 0) {
    await basketModule.removeBundle(bundleId)
  } else {
    await basketModule.updateBundle(bundleId, amount)
  }
}

const resetBuffer = (bundleId) => {
  bufferAmounts.value[bundleId] = bundleByBundleId(bundleId)?.amount
}

/** Меняет bundle'ы только ТЕКУЩЕЙ корзины */
export const useBundleAmounts = () => {
  return {
    bufferAmounts,
    bufferHasChanges,
    confirmBuffer,
    resetBuffer,
  }
}
