import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, unref as _unref, renderList as _renderList, Fragment as _Fragment, createTextVNode as _createTextVNode, createBlock as _createBlock, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

const _withScopeId = n => (_pushScopeId("data-v-775a2a90"), n = n(), _popScopeId(), n);

const _hoisted_1 = {
  class: "header"
};
const _hoisted_2 = ["src"];
const _hoisted_3 = {
  key: 0,
  class: "sublist"
};
const _hoisted_4 = {
  key: 1,
  class: "footer"
};
import { computed, ref } from "vue";
export default {
  __name: 'CatalogCategoryCard',
  props: ['parent', 'children'],

  setup(__props) {
    const props = __props;
    const listShowFirst = 3;
    const isExpanded = ref(false);
    const visibleChildren = computed(() => {
      let res;
      if (isExpanded.value) res = props.children;else res = props.children.slice(0, listShowFirst);
      return res;
    });

    const link = category => {
      return {
        name: 'catalog.items',
        params: {
          categoryId: category.id
        }
      };
    };

    return (_ctx, _cache) => {
      const _component_router_link = _resolveComponent("router-link");

      const _component_el_link = _resolveComponent("el-link");

      const _component_el_card = _resolveComponent("el-card");

      return _openBlock(), _createBlock(_component_el_card, {
        class: _normalizeClass([{
          cardClickable: props.list === null
        }, "categoryCard"])
      }, {
        default: _withCtx(() => [_createElementVNode("div", _hoisted_1, [_createVNode(_component_router_link, {
          to: link(props.parent),
          class: "title"
        }, {
          default: _withCtx(() => [_createElementVNode("h2", null, _toDisplayString(props.parent.title), 1)]),
          _: 1
        }, 8, ["to"]), props.parent.photo ? (_openBlock(), _createElementBlock("img", {
          key: 0,
          src: props.parent.photo,
          class: "img",
          height: "40"
        }, null, 8, _hoisted_2)) : _createCommentVNode("", true)]), _unref(visibleChildren) ? (_openBlock(), _createElementBlock("div", _hoisted_3, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(visibleChildren), child => {
          return _openBlock(), _createBlock(_component_router_link, {
            to: link(child),
            key: child.id,
            class: "subcat"
          }, {
            default: _withCtx(() => [_createTextVNode(_toDisplayString(child.title), 1)]),
            _: 2
          }, 1032, ["to"]);
        }), 128))])) : _createCommentVNode("", true), _unref(visibleChildren).length !== props.children.length ? (_openBlock(), _createElementBlock("div", _hoisted_4, [_createVNode(_component_el_link, {
          onClick: _cache[0] || (_cache[0] = $event => isExpanded.value = true)
        }, {
          default: _withCtx(() => [_createTextVNode("Показать ещё " + _toDisplayString(props.children.length - listShowFirst) + " категорий", 1)]),
          _: 1
        })])) : _createCommentVNode("", true)]),
        _: 1
      }, 8, ["class"]);
    };
  }

};